import { createSlice } from "@reduxjs/toolkit";

const mantraSlice = createSlice({
    name: "mantra",
    initialState: {
        items: []
    }, 
    reducers: {
        addItem: (state, action) => {
            // mutating the state here.
            state.items.push(action.payload);
        }
    }
});

export const { addItem } = mantraSlice.actions;

export default mantraSlice.reducer;