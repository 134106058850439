import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./blogSlice";
import mantraReducer from "./mantraSlice";
import paymentSlice from "./paymentSlice";

const appStore = configureStore({
    reducer: {
        blog: blogReducer,
        mantra: mantraReducer,
        payment: paymentSlice,
    },
}); 

export default appStore;