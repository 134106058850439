import React from 'react'
import { Outlet } from 'react-router-dom';

const Body = () => {
  return (
    <>
      <Outlet />
    </>
  )
}
 
export default Body;